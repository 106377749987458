'use client'

import * as Avatar from '@radix-ui/react-avatar'
import { tw } from 'utils/classnames'
import { propped } from 'utils/propped'

// eslint-disable-next-line no-barrel-files/no-barrel-files
export * from '@radix-ui/react-avatar'

export type AvatarColorVariant = 'primary' | 'secondary'
export type RootProps = Avatar.AvatarProps & {
  // show an outline/border around the avatar.
  outline?: boolean
  size?:
    | 'small'
    | 'medium'
    | 'mediumSecondary'
    | 'mediumTertiary'
    | 'mediumLarge'
    | 'semiLarge'
    | 'midLarge'
    | 'large'
    | 'largeSecondary'
    | 'xLarge'
  rounded?: 'full' | 'rounded'
  variant?: AvatarColorVariant
}
const sizeClasses = {
  small: 'min-w-[24px] w-6 h-[24px]',
  medium: 'min-w-[32px] w-8 h-[32px]',
  mediumSecondary: 'min-w-[36px] w-9 h-[36px]',
  mediumTertiary: 'min-w-[40px] w-10 h-[40px]',
  mediumLarge: 'min-w-[48px] w-12 h-[48px]',
  semiLarge: 'min-w-[56px] w-14 h-[56px]',
  midLarge: 'min-w-[64px] w-16 h-[64px]',
  large: 'min-w-[72px] w-[72px] h-[72px]',
  largeSecondary: 'min-w-[80px] w-[80px] h-[80px]',
  xLarge: 'min-w-[112px] w-[112px] h-[112px]',
}
export function Root({ outline, rounded = 'full', variant = 'secondary', ...props }: RootProps) {
  return (
    <Avatar.Root
      {...props}
      className={tw(
        // size
        sizeClasses[props.size ?? 'medium'],
        // appearance
        'text-service-white overflow-hidden bg-gray-700 text-base',
        // position
        'flex place-content-center place-items-center',
        // border or no border?
        outline && 'outline outline-[3px] outline-gray-900',
        // default circle or variant rounded corners
        rounded === 'full' && 'rounded-full',
        rounded === 'rounded' && 'rounded-2',
        variant === 'primary' && 'bg-violet-500',
        variant === 'secondary' && 'bg-gray-700',
        props.className,
      )}
    />
  )
}

export const Image = propped(Avatar.Image, {
  className: 'w-full h-full object-cover rounded-[inherit]',
})

export const Fallback = ({ delayMs = 600, ...props }) => (
  <Avatar.Fallback delayMs={delayMs} {...props} />
)

export function Skeleton({
  outline,
  rounded = 'full',
  variant = 'secondary',
  ...props
}: RootProps) {
  return (
    <div
      className={tw(
        // size
        sizeClasses[props.size ?? 'medium'],
        // appearance
        'text-service-white overflow-hidden rounded-full bg-gray-700 text-base',
        // position
        'flex place-content-center place-items-center',
        // border or no border?
        outline && 'outline outline-[3px] outline-gray-900',
        // default circle or variant rounded corners
        rounded === 'full' && 'rounded-full',
        rounded === 'rounded' && 'rounded-2',
        variant === 'primary' && 'bg-violet-500',
        variant === 'secondary' && 'bg-gray-700',
        props.className,
      )}
    />
  )
}
